import React from 'react';
import './Content.sass'

function Content({page_backgroundColor, ...props}) {
  return (
    <div style={{backgroundColor: page_backgroundColor}} className="content">
      { props.children }
    </div>
  );
}

export default Content;