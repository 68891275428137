import React from "react";
import "./Header.sass";
import Button from "./Button";
import { useSidebarDispatch, useSidebarState } from "../store/SidebarProvider";
import { useStreamInfo } from "../store/StreamInfoContext";
import Parser from "html-react-parser";
import { useChat } from "../store/ChatProvider";
import { openExhibition } from "./ExhibitionList";

export default function Header({ switchExtraSidebar }) {
  const sidebarDispatch = useSidebarDispatch();
  const sidebarState = useSidebarState();
  const streamInfo = useStreamInfo();
  const chatHandlers = useChat();

  const toggleSupport = () => {
    if (chatHandlers.isOpen()) {
      chatHandlers.close();
      chatHandlers.hide();
    } else {
      chatHandlers.show();
      chatHandlers.open();
    }
  };

  const openUrlButton = () => {
    if (!!streamInfo["url_tab"]) {
      const win = window.open(streamInfo["url_button"], "_blank");
      win.focus();
    } else {
      openExhibition({
        title: streamInfo["url_button_title"],
        url: streamInfo["url_button"],
        width: streamInfo["url_tab_width"],
        height: streamInfo["url_tab_height"],
      });
    }
  };

  return (
    <header
      style={{
        background: streamInfo.header_backgroundColor,
        color: streamInfo.header_background_font_color,
      }}
      className="header"
    >
      <div className="header-left">
        {streamInfo["show_program"] && (
          <Button
            onClick={() =>
              sidebarDispatch({
                type: "leftSidebarOpen",
                open: !sidebarState.leftSidebarOpened,
              })
            }
          >
            {sidebarState.leftSidebarOpened
              ? streamInfo["hide_program"]
              : streamInfo["show_program"]}
          </Button>
        )}
        {streamInfo["support_button"] && (
          <Button onClick={toggleSupport}>
            {" "}
            {streamInfo["support_button"]}{" "}
          </Button>
        )}
        {streamInfo["directory_button"] && (
          <Button
            onClick={() =>
              (window.location.href = streamInfo["directory_href"])
            }
          >
            {streamInfo["directory_button"]}
          </Button>
        )}
      </div>
      <div className="header-center">
        <div>
          {streamInfo.header_title_img_url && (
            <img
              style={{
                height: streamInfo.header_title_img_height,
                width: streamInfo.header_title_img_width,
              }}
              src={streamInfo.header_title_img_url}
              alt=""
            />
          )}
        </div>
        <span className="header-title">{Parser(streamInfo.header_title)}</span>
      </div>
      <div className="header-right">
        {streamInfo["show_exhibition"] && (
          <Button
            onClick={() =>
              sidebarDispatch({
                type: "rightSidebarOpen",
                open: !sidebarState.rightSidebarOpened,
              })
            }
          >
            {sidebarState.rightSidebarOpened
              ? streamInfo["hide_exhibition"]
              : streamInfo["show_exhibition"]}
          </Button>
        )}

        {streamInfo["chat_button"] && (
          <Button onClick={switchExtraSidebar}>
            {" "}
            {streamInfo["chat_button"]}{" "}
          </Button>
        )}

        {streamInfo["url_button_title"] && (
          <Button onClick={openUrlButton}>
            {" "}
            {streamInfo["url_button_title"]}{" "}
          </Button>
        )}
      </div>
    </header>
  );
}
