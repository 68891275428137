import React, { useEffect, useRef } from "react";
import './RPlayer.sass';
import ReactPlayer from "react-player";

function RPlayer({ url, time }) {
  const playerRef = useRef();

  useEffect(() => {
    playerRef.current.seekTo(0)
  },[])

  useEffect(() => {
    playerRef.current.seekTo(time)
  },[time])

  return (
         <ReactPlayer 
            width='100%'
            height='100%'
            className='r-player'
          ref={playerRef}
          url={url}
          controls
          config={{
            youtube: {
              playerVars: { showinfo: 1 }
            },
           vimeo: {
             playerOptions: {
              maxwidth: 1,
              maxheight: 1,
             }
           }
          }}
          />
  );
}

export default RPlayer;
